import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const Ebook = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "71cVD5fgy7L.jpg" }) {
        desktop: childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
        mobile: childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const linkStyle = {
    fontSize: '1.2rem',
    fontWeight: '400',
    display: 'block',
    marginBottom: '1rem',
    boxSizing: 'border-box',
  };

  const imgStyle = {
    border: 'solid 1px black',
  };

  return (
    <Layout location={location}>
      <Seo title='Book' />

      <div style={{ display: 'flex' }}>
        <div
          className='book__image-wrapper'
          style={{
            width: '1400px',
            margin: '0 auto',
            marginBottom: `1.45rem`,
            marginRight: '2rem',
            boxShadow: '0px 0px 6px #fff',
          }}
        >
          <Img imgStyle={imgStyle} fluid={data.file.desktop.fluid} />
        </div>

        <div>
          <p style={{ fontSize: '1.2rem' }}>
            Ten years ago, I began going through old letters and journals and
            compiling stories of our life as missionaries. After collecting over
            one hundred and fifty stories, I decided to write them down for
            posterity. The result is this book, "God My Rock".
          </p>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
              className='mobile-only'
            >
              <div
                className='book__image-wrapper-mobile'
                style={{
                  marginBottom: `1.45rem`,
                }}
              >
                <Img imgStyle={imgStyle} fixed={data.file.mobile.fixed} />
              </div>
              <div style={{ padding: '8px', backgroundColor: '#fafafa' }}>
                <h3>Free e-book download of "God, My Rock"</h3>
                <p>
                  You can download a free copy of my book{' '}
                  <em>
                    <strong>God, My Rock</strong> Faith Adventures of a
                    Missionary Mom
                  </em>{' '}
                  at the link below.
                </p>
                <a
                  href='https://drive.google.com/drive/folders/17OBt4v4NLwCBmXVR6X6rpkh4tMAAo4Nd?usp=sharing'
                  className='my-button'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{
                    ...linkStyle,
                    margin: '0 auto',
                    textAlign: 'center',
                  }}
                >
                  Download the E-book
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Ebook;
